import { Controller } from "@hotwired/stimulus"
import mutationEvents from 'mutation-events'; // Fixes Chrome's depreciation of DOM mutation events

// Connects to data-controller="flash"
export default class extends Controller {
  connect() {
    let flash = this.element.querySelector('.flash'); 
    this.element.addEventListener('click', () => {
      this.flashClose(flash);
    })
    setTimeout(() => {
      this.flashClose(flash);
    }, 4000)
  }

  flashClose(flash){
    flash.style.opacity = '0';
    setTimeout(()=>{
      flash.innerHTML = null;
    }, 1000)
  }
}
