import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="expnd-txt"
export default class extends Controller {
  static targets = ['body', 'btn']
  connect() {
    console.log('connect')
  }

  open() {
    this.bodyTarget.classList.toggle('open');
    let svg = this.btnTarget.querySelector('svg');
    svg.classList.toggle('spin');    
  }
}
