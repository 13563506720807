import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

export default () => {
  const swiperHero = new Swiper('.swiper-hero', {
    modules: [Navigation, Pagination, Autoplay],    
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    spaceBetween:0,
    autoplay: {
      enabled: true,
      delay: 5000,
    },
    pagination: {
      el:'.swiper-pagination-hero',
      clickable: true,
      renderBullet: function () {
        return '<span class="swiper-pagination-bullet">'+"</span>";
      },
    },  
  });

  const swiperSpotlight = new Swiper('.swiper-spotlight', {
    modules: [Navigation, Pagination, Autoplay],    
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    spaceBetween:0,
    autoplay: {
      enabled: true,
      delay: 5000,
    },
    pagination: {
      el:'.swiper-pagination-spotlight',
      clickable: true,
      renderBullet: function () {
        return '<span class="swiper-pagination-bullet">'+"</span>";
      },
    },  
  });

  const swiperFeatEvents = new Swiper('.swiper-feature-events', {
    modules: [Navigation, Pagination],    
    direction: 'horizontal',
    loop: false,
    slidesPerView: 4,
    spaceBetween:30,
    pagination: {
      el:'.swiper-pagination-featured',
      clickable: true,
      renderBullet: function () {
        return '<span class="swiper-pagination-bullet">'+"</span>";
      },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 0,
        centerInsufficientSlides: true,
        centeredSlides: true,
      },
      435: {
        slidesPerView: 1.2,
        spaceBetween: 10,
        centerInsufficientSlides: true,
        centeredSlides: true,
      },
      650: {
        slidesPerView: 1.5,
        spaceBetween: 20,
        centerInsufficientSlides: true,
        centeredSlides: true,
      },
      740: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      990: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 30,
      }
    },
    
  });
  
};