import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="iframe-loading"
export default class extends Controller {
  connect() {    
    () => {
      dataLayer.push({
        "event": "spektrix_pageview",
        "spektrix_location": this.src
      });
    };
  }
}
