import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="link-share"
export default class extends Controller {
  static targets = ["sharebtn"]; 

  share(event) {
    event.preventDefault();
    
    const flash = document.querySelector('#flash');
    let card = this.sharebtnTarget.closest('.popup-card');
    let title = card.querySelector('.popup-card__info > h3').textContent;
    let text = card.querySelector('.popup-card__info > .popup-card__info--genre').textContent;
    let date = card.querySelector('.popup-card__info > .popup-card__info--date').textContent;
    let link = card.querySelector('.popup-card__info > div > .btn-secondary').href;

    if(navigator.share){
      navigator.share({
        title: title,
        text: text + date,
        url: link,
      })
      .then(() => {flash.innerHTML = '<div class="flash notice">Thanks for the share!</div>'})
      .catch((error) => {console.log('Error share', error)});
    }else{
      navigator.clipboard.writeText(link).then(() => {
        flash.innerHTML = '<div class="flash notice">Copied to clipboard</div>'
      });
      console.log('copied');
    }


  }

  connect() {
  }
}
