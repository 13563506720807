import navbar from './navbar';
import swiperInit from './swiper';
import pikadayFilters from './pikadayFilters';
import priceRangeSlider from './price_range_slider';
import checkboxInsert from './checkbox_insert';
import Safari from './safari';

(() => {
  document.addEventListener('turbo:load', () => {
    navbar();
    swiperInit();
    pikadayFilters();
    priceRangeSlider();
    checkboxInsert();
    Safari();
	})

  document.addEventListener('turbo:frame-load', () => {
    swiperInit();
    priceRangeSlider();
    checkboxInsert();
  })
})();
