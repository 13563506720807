import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["select"]
  static values = {
    url: String,
    param: String
  }

  connect() {
  }

  change(event) {
    let value = event.target.selectedOptions[0].value.toLowerCase()
    let params = new URLSearchParams()
    params.append("target", this.selectTarget.id)

    get(`/cms/${value}s/options_for_select?${params}`, {
      responseKind: "turbo-stream"
    })
  }
}
