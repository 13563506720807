export default () => {
  
  let checkSpans = document.querySelectorAll("span.checkbox > label");
  let newCheck = '<span class="checkmark"></span>';

  if(checkSpans){
    [...checkSpans].forEach(e => {
      e.innerHTML += newCheck;
    });
  }

};