export default () => {

  const header = document.querySelector('header');
  let scrollPos = window.scrollY

  const addClass = () => header.classList.add("sticky")
  const removeClass = () => header.classList.remove("sticky")

  if(!header){return}

  if(scrollPos >= "3"){
    addClass();
  } 

  window.addEventListener('scroll', () => {    
    
    scrollPos = window.scrollY

    if(scrollPos >= "3"){
      addClass();
    } else {
      removeClass();  
    }

  })

}