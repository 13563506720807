import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="banner-img"
export default class extends Controller {
  static targets = ["container", "btn"]

  connect() {
    const mobileImg = this.containerTarget.getAttribute("data-mobile")
    const desktopImg = this.containerTarget.getAttribute("data-desktop")
    if(window.innerWidth > 767){
      if(desktopImg){
        this.containerTarget.style.backgroundImage = `url('${desktopImg}')`;
      }
    } else {
      if(mobileImg){
        this.containerTarget.style.backgroundImage = `url('${mobileImg}')`;
      } else {
        this.containerTarget.style.backgroundImage = `url('${desktopImg}')`;
      }
    }
  }

  bookinglink() {
    const aCont = this.element.querySelector('a')
    const btn = this.btnTarget
    const url = btn.getAttribute("data-url") 
    
    aCont.addEventListener("click", (e) => {
      if (e.target.nodeName == "SPAN") {
        e.preventDefault();
        window.location.href = url;
      }
    })
  }
  
}
