import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="swipercontent"
export default class extends Controller {
  connect() {
    const catText = this.element.querySelector(".swiper-slide__content--cat-text")
    const headline = this.element.querySelector(".swiper-slide__content--headline")
    const trix = this.element.querySelector(".trix-content")
    
    if(catText.innerText == "" && headline.innerText == "" && trix.innerText == ""){
      this.element.style.backgroundColor = "transparent"
    }
  }

}
