import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobile-filters-toggle"
export default class extends Controller {

  static targets = ["btn", "modal"]; 

  connect() {

  }

  toggle(){
    this.modalTarget.classList.toggle('open');
  }
}
