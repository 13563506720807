export default () => {
  
  let navLinks = [...document.querySelectorAll('.primary-navigation > .nav__icons > a')]
  
  navLinks.forEach(e => {
    let text = e.querySelector('span');
    e.addEventListener('mouseenter', () => {
      text.classList.add('open');
    })
    e.addEventListener('mouseleave', () => {
      text.classList.remove('open');
    })
  });
  
};