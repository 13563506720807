import * as noUiSlider from 'nouislider';


export default () => {

  let slider = document.getElementById('price-range-slider');

  if(slider){
        
    noUiSlider.create(slider, {
      start: [0, 100],
      connect: true,
      tooltips: true,
      format: {
        from: function(value) {
              return parseInt(value);
            },
        to: function(value) {
              return parseInt(value);
            }
        },
      range: {
        'min': 0,
        'max': 100
      }
    });

    var inputMin = document.getElementById('price-range-min');
    var inputMax = document.getElementById('price-range-max');
    let filterForm = document.querySelector('#filter-sidebar');

    formSubmit = () => {
      filterForm.submit();
    }

    slider.noUiSlider.on('change', function (values, handle) {
      var value = values[handle];
      if (handle) {
        inputMax.value = value;
        if(inputMax.value){
          formSubmit();
        }
      } else {
        inputMin.value = value;
        if(inputMax.value){
          formSubmit();
        }
      }
    });

    inputMin.addEventListener('change', function () {
      slider.noUiSlider.set([this.value, null]);
    });

    inputMax.addEventListener('change', function () {
      slider.noUiSlider.set([null, this.value]);
    });


    slider.noUiSlider.set([inputMin.value, inputMax.value]);



  };

};
