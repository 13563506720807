import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="event-access"
export default class extends Controller {
  static targets = ["about", "access", "info"]; 

  aboutShow() {
    let about = this.aboutTarget.classList
    let access = this.accessTarget.classList
    let info = this.infoTarget.classList

    if(about.contains('hidden')){
      about.remove('hidden')
      about.add('show')

      access.remove('show')
      access.add('hidden')
      info.remove('show')
      info.add('hidden')
    }
  }
  
  accessShow() {
    let about = this.aboutTarget.classList
    let access = this.accessTarget.classList
    let info = this.infoTarget.classList

    if(access.contains('hidden')){
      access.remove('hidden')
      access.add('show')

      about.remove('show')
      about.add('hidden')
      info.remove('show')
      info.add('hidden')
    }
  }

  infoShow() {
    let about = this.aboutTarget.classList
    let access = this.accessTarget.classList
    let info = this.infoTarget.classList

    if(info.contains('hidden')){
      info.remove('hidden')
      info.add('show')

      about.remove('show')
      access.remove('show')
      about.add('hidden')
      access.add('hidden')
    }
  }
}
