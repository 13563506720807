export default () => {
  const openBtn = document.querySelectorAll('.open-btn');
  const closeBtn = document.querySelectorAll('.close-btn');
  const btns = [...openBtn, ...closeBtn];
  const overlays = [...document.querySelectorAll('.overlay')];

  btns.forEach(btn => {
    btn.addEventListener("click", (e) => {
      e.preventDefault;
      var target = btn.dataset.openTarget
      overlays.forEach(overlay => {
        if(overlay.classList.contains(target)){
          overlay.classList.toggle('open');
        }
      });
    })
  });
  
};