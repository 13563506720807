import "@hotwired/turbo-rails"
import "@rails/actiontext"
import "./controllers"
import './modules/index'
import { Fancybox } from "@fancyapps/ui"

Fancybox.bind('[data-fancybox="gallery"]', {
  Toolbar: {
    display: {
      left: ["infobar"],
      middle: [],
      right: ["close"],
    },
  },
});


window.initMap = function(...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
}


if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/service-worker.js', { scope: './' })
                         .then(function(registration) {
                           console.log('[Companion]', 'Service worker registered!')
                         })
}

