import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="expand-toggle"
export default class extends Controller {
  connect() {
  }

  toggle(event){
    this.element.classList.toggle('open');
  }
}
