import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="process-feedback"
export default class extends Controller {
  connect() {

    let btns = [...document.querySelectorAll("[data-controller='process-feedback']")]
    let labels = [...document.querySelectorAll("label:has([data-controller='process-feedback'])")]

    const classRemove = () => {
      labels.forEach(label => {
        if(label.classList.contains('clicked')){
          label.classList.remove('clicked');
        }
      })
    };

    btns.forEach(btn => {
      let radio = btn.closest('label').querySelector('input[type=radio]')
      let label = btn.closest('label')
      radio.addEventListener('change', () => {
        if(radio.checked == true){
          classRemove();
          label.classList.add('clicked')
        }  
      })
    })  ;
    

  }
}
