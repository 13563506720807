import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="jump-btn"
export default class extends Controller {
  connect() {
    const arrows = this.element
    window.addEventListener("scroll", () => {
      if(arrows.getBoundingClientRect().top < 550){
        arrows.classList.add('hidden');
      } else {
        arrows.classList.remove('hidden');
      }
    })
  }
  hide() {
    const eventInfo = document.querySelector('.event__single')
    eventInfo.scrollIntoView();
  }
}
