import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="soc-exp"
export default class extends Controller {

  static targets = ["grid"]

  expand() {
    let height = this.gridTarget.offsetHeight;
    let cardHeight = this.element.querySelector('.insta-import').offsetHeight;
    let expandHeight = cardHeight * 2 + 40;
    height = height + expandHeight;
    this.gridTarget.style.height = `${height}px`
  }
}
