import Pikaday from 'pikaday';

export default () => {

  var startDate,
  endDate,
  updateStartDate = function() {
    startPicker.setStartRange(startDate);
    endPicker.setStartRange(startDate);
    endPicker.setMinDate(startDate);
  },
  updateEndDate = function() {
    startPicker.setEndRange(endDate);
    startPicker.setMaxDate(endDate);
    endPicker.setEndRange(endDate);
  },
  
  startPicker = new Pikaday({
    field: document.getElementById('startDate'),
    minDate: new Date(),
    maxDate: new Date(2020, 12, 31),
    toString(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${year}/${month}/${day}`;
    },
    onSelect: function() {
      startDate = this.getDate();
      updateStartDate();
    }
  }),
  
  endPicker = new Pikaday({
    field: document.getElementById('endDate'),
    minDate: new Date(),
    maxDate: new Date(2020, 12, 31),
    toString(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${year}/${month}/${day}`;
    },
    onSelect: function() {
      endDate = this.getDate();
      updateEndDate();
    }
  }),
  _startDate = startPicker.getDate(),
  _endDate = endPicker.getDate();

  if (_startDate) {
    startDate = _startDate;
    updateStartDate();
  }

  if (_endDate) {
    endDate = _endDate;
    updateEndDate();
  }

};
